<template>
    <div class="login_form">
        <div class="headB">
            <img src="../assets/logo/back_1.png" alt="" width="20" @click="goback">
            <span style="color:#555555;font-size:20px;">
        {{ }}
      </span>
            <span>{{  }}</span>
        </div>

        <!-- <div>
          <img src="../assets/netmage/mei.png" style="width:100%;height:50%">
        </div> -->
        <!--        <div style="color: #fff;margin-top:50px; font-size: x-large;margin-left: -40%;">{{ $t('uc.finance.xx162') }}-->
        <!--        </div>-->

        <div class="login_right">
            <p class="login">{{ $t('uc.login.login') }}</p>
            <!--            <img src="../assets/logo/jinbet.png" width="120" style="margin-top: 40px;">-->
            <!-- <img src="../assets/logo/logojb.png" width="120" style="margin-top:10%;margin-left:-70%"> -->
            <Form ref="formInline" :model="formInline" :rules="ruleInline" inline style="margin-top: 10%;">
                <FormItem prop="user" class="captcha">
                    <Input type="text" size="large" v-model="formInline.username" :placeholder="$t('uc.login.usertip')"
                           clearable border>
                        <Select v-model="country" slot="prepend" style="width: 85px;">
                            <img src="../assets/logo/pad.png" slot="prefix" width="20" alt="">
                            <Option value="+855" label="+855"><span>+855</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.cambodia') }}</span></Option>
                            <Option value="+86" label="+86"><span>+86</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.china') }}</span></Option>
                            <Option value="+65" label="+65"><span>+65</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.singapore') }}</span></Option>
                            <Option value="+82" label="+82"><span>+82</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.korea') }}</span></Option>
                            <Option value="+81" label="+81"><span>+81</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.japan') }}</span></Option>
                            <Option value="+66" label="+66"><span>+66</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.thailand') }}</span></Option>
                            <Option value="+84" label="+84"><span>+84</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.vietnam') }}</span></Option>
                            <Option value="+62" label="+62"><span>+62</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.india') }}</span></Option>
                            <Option value="+852" label="+852"><span>+852</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.hk') }}</span></Option>
                            <Option value="+60" label="+60"><span>+60</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.malaysia') }}</span></Option>
                            <Option value="+886" label="+886"><span>+886</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.taiwan') }}</span></Option>
                            <Option value="+673" label="+673"><span>+673</span><span
                                style="margin-left:10px;color:#ccc">{{ $t('uc.regist.wenlai') }}</span></Option>
                        </Select>
                    </Input>
                </FormItem>
                <FormItem prop="password" class="captcha">

                    <Input type="password" v-model="formInline.password" size="large"
                           :placeholder="$t('uc.login.pwdtip')" @on-keyup="onKeyup" password>
                        <!--                        <img src="../assets/logo/password.png" slot="prefix" width="20" style="margin-top: 10px;"-->
                        <!--                             alt="">-->
                    </Input>
                </FormItem>
                <p id="notice" class="hide">{{ $t('uc.login.validatemsg') }}</p>
                <FormItem style="margin-bottom:15px;width: 85%">
                    <Button class="login_btn" @click="handler">{{ $t('uc.login.login') }}</Button>
                </FormItem>
                <!--忘记密码-->
                <div class='to_register'>
                    <img src="../assets/logo/1212.png" width="20" alt="">
                    <router-link to="/findPwd" style="color: #fff;">{{ $t('uc.login.forget') }}</router-link>
                </div>
                <div style="margin-top: 10px;">
                    <span style="color: #b1bad3;">{{ $t('uc.finance.xx163') }}</span>
                    <router-link to="/MobileRegister" style="color: #fff;">{{ $t('uc.login.goregister') }}</router-link>
                </div>
                <!-- <div class='to_register_1'>
               </div> -->
            </Form>
        </div>
    </div>

</template>
<style scoped lang="scss">
/* 验证码 */
.login_form {
    //background-image: url(../assets/logo/login_bg.png);
    //background-repeat: no-repeat;
    //background-position: center;
    //background-size: 100% 100%;
    background-color: #0E212E;
    height: 100vh;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .login_right {
        background-color: #1A2C38;
        padding: 10px;
        width: 94%;
        border-radius: 10px;
        height: 350px;

        .login {
            color: #fff;
            font-weight: 600;
            font-size: 18px;
        }

        form.ivu-form.ivu-form-label-right.ivu-form-inline {
            .login_title {
                height: 70px;
                color: #fff;
                font-size: large;
            }
        }
    }

    .header_nav {
        margin-top: 20px;

    }

    .login_btn.ivu-btn {
        width: 100%;
        height: 42px;
        border-radius: 4px;
        outline: none;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 15px;
        border: none;
        color: #000;
        font-weight: 600;
        background-color: #00E700;

        &:focus {
            -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
            -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
            box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
        }
    }

    .to_register {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: #fff;
        font-weight: bold;

    }

    .to_register_2 {
        margin-top: 100px;
        color: aliceblue;

    }

    .to_register_1 {
        margin-top: 50px;
        font-family: rabikiso;
        font-style: italic;
        color: #3d56a2;
    }
}

.captcha {
    width: 85%;
    margin-left: 10px;
}

.show {
    display: block;
}

.hide {
    display: none;
}

#notice {
    color: red;
}

#wait {
    text-align: left;
    color: #666;
    margin: 0;
}

.geetest_wait_dot geetest_dot_1 {
    color: red;
}

.user .ivu-btn,
.ivu-btn:active,
.ivu-btn:focus {
    border-color: #d7dde4;
    box-shadow: none;
}

/*  */
</style>
<script>

import {getQueryVariable} from '@/config/index'
import {page} from 'vue-analytics'

export default {
    data() {
        return {
            from: '',
            country: "+855",

            captchaObj: null,
            fullscreen: false,
            // _captchaResult: null,
            formInline: {
                username: "",
                password: "",
            },
            iwidth: '',
            iHeight: '',
            ruleInline: {
                username: [
                    {
                        required: true,
                        message: this.$t("uc.login.loginvalidate"),
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: this.$t("uc.login.pwdvalidate1"),
                        trigger: "blur"
                    },
                    {
                        type: "string",
                        min: 6,
                        message: this.$t("uc.login.pwdvalidate2"),
                        trigger: "blur"
                    }
                ]
            },
            options: [
                {
                    title: "en-US",
                    cardImage: "../assets/img/icons8-usa-96.png"
                },
                {
                    title: "zh-HK",
                    cardImage: "../assets/img/icons8-hongkong-96.png"
                },
                {
                    title: "zh-CN",
                    cardImage: "../assets/img/icons8-china-96.png"
                },

            ],
            country_en: ''
            // <img class="lang-img" src='../assets/img/icons8-usa-96.png' v-if="lang=='en_US'"/>
            // 			<img class="lang-img" src='../assets/img/icons8-japan-96.png' v-if="lang=='ja_JP'"/>
            // 			<img class="lang-img" src='../assets/img/icons8-france-96.png' v-if="lang=='fr_FR'"/>
            // 			<img class="lang-img" src='../assets/img/icons8-hongkong-96.png' v-if="lang=='zh_HK'"/>
            // 			<img class="lang-img" src='../assets/img/icons8-china-96.png' v-if="lang=='zh_CN'"/>
        };
    },
    created: function () {
        this.init();
        this.iwidth = document.documentElement.clientWidth;
        this.iHeight = document.documentElement.clientHeight;
    },
    beforeRouteEnter(to, from, next) {
        next(vm =>{
            if (from.name === 'register') {
                vm.from = 'register'
            }
        })
    },
    computed: {
        isLogin: function () {
            return this.$store.getters.isLogin;
        },


        languageValue: function () {
            var curlang = this.$store.getters.lang;
            if (curlang == "en-US") {
                return "English";
            }
            if (curlang == "ja-JP") {
                return "日本語";
            }
            if (curlang == "ko-KR") {
                return "한국어";
            }
            if (curlang == "de_DE") {
                return "Deutsche";
            }
            if (curlang == "fr_FR") {
                return "français";
            }
            if (curlang == "it_IT") {
                return "italiano";
            }
            if (curlang == "es_ES") {
                return "Español";
            }
            if (curlang == "zh-HK") {
                return "繁體中文";
            }
            if (curlang == "zh-CN") {
                return "简体中文";
            }
            return curlang;
        },

        lang() {
            return this.$store.state.lang;
        },
    },
    methods: {
        track() {
            page('/')

        },
        goback() {
            if(this.from === 'register'){
                this.$router.push({path:"/"})
            }else{
                this.$router.back(-1)
            }
        },
        toRegister() {
            this.$router.push("/MobileRegister");
        },

        somebodyLogin() {
            let member = {}
            member.isNobody = true
            this.$store.commit("setMember", member);
            this.$router.push("/about");
        },

        changelanguage: function (name) {
            console.log("change language: " + name);
            this.$store.commit("setlang", name);
            this.$i18n.locale = name;
            this.country_en = name
        },

        init() {
            // this.$store.commit("navigate", "nav-other");
            // this.$store.state.HeaderActiveName = "0";
            // http://xun.ioapp.xyz?mobileTo=9999999&code=%2522%252B855%2522&isThird=1&orderNo=123123123&payMethod=TGSF&isThird=1&tradeAmount=123
            // if (getQueryVariable("mobileTo") != null) {
            //   this.$store.commit("setMobile", getQueryVariable("mobileTo"));

            //     }
            // if (getQueryVariable("isThird") != null) {
            //    this.$store.commit("setThird", getQueryVariable("isThird"));

            // }

            // if (getQueryVariable("orderNo") != null) {
            //    this.$store.commit("setOrderNo", getQueryVariable("orderNo"));

            // }

            // if (getQueryVariable("payMethod") != null) {
            //    this.$store.commit("setPayMethod", getQueryVariable("payMethod"));

            // }

            // if (getQueryVariable("amount") != null) {
            //    this.$store.commit("setAmount", getQueryVariable("amount"));

            // }

            // if (getQueryVariable("currencyEnum") != null) {
            //    this.$store.commit("setCurrency", getQueryVariable("currencyEnum"));

            // }
            this.track();
            if (getQueryVariable("agentId")) {
                this.$store.commit("setCurrency", getQueryVariable("agentId"));
            } else if (getQueryVariable("tgId")) {
                let tgid = getQueryVariable("tgId")
                let amount = getQueryVariable("amount")
                let gameid = getQueryVariable("gameId")
                this.$router.push({name: "TgTransition", params: {tgid, amount, gameid}}); //添加红包判断
                // http://test.jb88.bet/?tgId=5402264229&amount=12333&gameId=80&time=1670931645&sign=6b41cdca24f6f259eb69a21743f1b95d
            }

            // if (this.isLogin) {
            //   this.$router.replace("/");
            // }

            let lang = localStorage.getItem('LANGUAGE');
            this.country_en = lang || "zh-CN"
            this.$i18n.locale = this.country_en;
            // else {
            //   this.initGtCaptcha();
            // }


        },


        onKeyup() {
            // if (ev.keyCode == 13) {
            //   $(".login_btn").click();
            // }
        },
        // initGtCaptcha() {
        //   var that = this;
        //   this.$http.get(this.host + this.api.uc.captcha).then(function(res) {
        //     window.initGeetest(
        //       {
        //         // 以下配置参数来自服务端 SDK
        //         gt: res.body.gt,
        //         challenge: res.body.challenge,
        //         offline: !res.body.success, //表示用户后台检测极验服务器是否宕机
        //         new_captcha: res.body.new_captcha, //用于宕机时表示是新验证码的宕机
        //         product: "bind",
        //         width: "100%"
        //       },
        //       this.handler
        //     );
        //   });
        // },

        handler() {

            this.handleSubmit("formInline"); // 屏蔽了验证

        },

        // goback() {
        //     //获取个人安全信息
        //     this.$router.back(-1)
        //
        // },
        androidGo() {
            location.href = "https://www.jinbei-praiseclub.com/app-release.apk";
        },
        iosGo() {
            location.href = "https://apps.apple.com/us/app/conch-wallet/id1596692948";
        },
        logout() {
            this.$http.post(this.host + "/uc/logout", {}).then(response => {
                var resp = response.body;
                if (resp.code == 0) {
                    localStorage.setItem("MEMBER", JSON.stringify(null));
                    localStorage.setItem("TOKEN", null);
                    localStorage.removeItem("USERKEY", null);
                } else {
                    // this.$Message.error(resp.message);
                }
            });
        },

//     code: "+855"
// deviceInfo: "3e3c41b2-62fd-48b3-b51e-fd202c19b456"
// mobile: "87981312"
// password: "icnuLbWZg/ycHWHwrw6G+A=="
        handleSubmit(name) {
            // 不带验证

            this.$refs[name].validate(valid => {
                if (valid) {
                    var params = {};
                    let xy = this.country.substring(1, this.country.length)
                    params['code'] = xy
                    params['deviceInfo'] = "3e3c41b2-62fd-48b3-b51e-fd202c19b456"
                    params["mobile"] = this.formInline.username;
                    params["password"] = this.formInline.password;
                    params["fromPc"] = 1;

                    this.$http.post(this.host + this.api.uc.login, params).then(response => {
                        var resp = response.body;
                        if (resp.code == "0000") {
                            // this.$Message.success(this.$t("uc.login.success"));
                            // this.$Notice.success({
                            //     title: this.$t("common.tip"),
                            //     desc:resp.mesg
                            //   });
                            this.$store.commit("setMember", resp.data);
                            this.$store.commit('setCode', xy);
                            localStorage.setItem('TOKEN', resp.data.token);
                            if (this.$route.query.key != null && this.$route.query.key != "") {
                                localStorage.setItem("USERKEY", this.$route.query.key);
                            }

                            this.$http.post(this.host + this.api.uc.joinList).then(res => {  //请求活动接口
                                let result = res.body
                                if (result.code == '0000') {
                                    if (result.data != null && result.data.length > 0) {
                                        let activityId = result.data[0].activityId
                                        let activityType = result.data[0].activityType
                                        this.$router.push({name: "About", params: {activityId, activityType}}); //添加红包判断
                                    } else
                                        this.$router.push({name: "About"}); //添加红包判断
                                } else {
                                    this.$Notice.error({
                                        title: this.$t("common.tip"),
                                        desc: resp.mesg
                                    });
                                }

                            })


                        } else {
                            // this.$Message.error(resp.mesg);
                            this.$Notice.error({
                                title: this.$t("common.tip"),
                                desc: resp.mesg
                            });
                        }
                    });
                }
            });
            /* 带验证*/
            // var result = this._captchaResult;
            // if (!result) {
            //   $("#notice").show();
            //   setTimeout(function() {
            //     $("#notice").hide();
            //   }, 2000);
            // } else {
            //   this.$refs[name].validate(valid => {
            //     if (valid) {
            //       var params = {};
            //       params["username"] = this.formInline.user;
            //       params["password"] = this.formInline.password;
            //       this.$http.post(this.host + this.api.uc.login, params).then(response => {
            //           var resp = response.body;
            //           if (resp.code == 0) {
            //             this.$Message.success(this.$t("uc.login.success"));
            //             this.$store.commit("setMember", response.body.data);
            //             if (this.$route.query.key != null && this.$route.query.key != "") {
            //               localStorage.setItem("USERKEY", this.$route.query.key);
            //             }
            //             this.$router.push("/uc/safe");
            //           } else {
            //             this.$Message.error(resp.message);
            //           }
            //         });
            //     }
            //   });
            // }

        }
    }
};
</script>
<style lang="scss" scoped>

.cardx {
    width: 30%;
    background-color: $color-text-l;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    position: fixed;
    top: 0;
    display: flex;
    height: 50px;
    padding: 10px;
    width: 100%;
    align-items: baseline;
    z-index: 200;
}

.memo1text {
    color: aliceblue;
    font-size: medium;
    font-weight: 600;
}

.memo2text {
    color: aliceblue;
    font-size: smaller;
}


/deep/ .ivu-input-group-prepend {
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    color: #fff;
}

.ivu-form-item-error .ivu-select-arrow {
    color: #808695;
}

.login_right .ivu-select-dropdown {
    background: #1c2a32;
}

/deep/ .ivu-select {
    color: #fff;
}

/deep/ .ivu-input {
    // border: 1px;
    border-radius: 8px;
    border: none;
    background-color: #0E212E;
    color: #fff;
}

/deep/ .ivu-input::-moz-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

/deep/ .ivu-input::-webkit-input-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

/deep/ .ivu-input::-ms-input-placeholder {
    color: $color-highlight-background;
    font-size: medium;
}

.headB {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: $head;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    z-index: 200;
    width: 100%;
}

.lang-img {
    height: 20px;
    margin-bottom: -5px;
    margin-right: 5px;
}

.lang-item {
    text-align: left;

    img {
        height: 20px;
        margin-bottom: -5px;
        margin-right: 5px;
    }

    &:hover {
        background: #2a80f1;
    }
}

/deep/ .ivu-form-item-content {
    border: 1px solid #2f4553;
    border-radius: 8px;
}
</style>
<style>

.ivu-select-arrow {
    right: 4px;
}

.ivu-select-item span:first-child {
    display: inline-block;
    width: 30px;
    text-align: left;
}

/deep/ .ivu-form-item-content {
//border: 1px solid #2f4553;
}


</style>
